import * as React from 'react'
import { useRouter } from 'next/router'

export const HistoryContext = React.createContext(undefined)
export const useHistoryContext = () => React.useContext(HistoryContext)

export const usePreviousRoute = () => {
  const { asPath } = useRouter()
  const [state, setState] = React.useState({
    index: 0,
    history: new Map().set(0, asPath),
  })
  const { index, history } = state
  const previousRoute = history.get(index - 1 > 0 ? index - 1 : 0)

  const removeHistory = () => {
    setState(({ index, history }) => {
      const removeIndex = index - 1
      const hasHistory = removeIndex >= 0

      if (hasHistory) {
        history.delete(index)
      }

      return {
        index: hasHistory ? removeIndex : 0,
        history: new Map(history),
      }
    })
  }

  React.useEffect(() => {
    setState(({ index, history }) => {
      const nextIndex = index + 1
      const isSameRoute = history.get(index) === asPath

      if (!isSameRoute) {
        history.set(nextIndex, asPath)
      }

      return {
        index: !isSameRoute ? nextIndex : index,
        history: new Map(history),
      }
    })
  }, [asPath])

  return { history, previousRoute, removeHistory }
}

export const HistoryProvider = ({ children }) => {
  const historyProps = usePreviousRoute()

  return (
    <HistoryContext.Provider
      value={{
        ...historyProps,
      }}
    >
      {children}
    </HistoryContext.Provider>
  )
}
