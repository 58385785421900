export const LOCAL_STORAGE_KEY_CROSS_SELL_DISCOUNT_ACTIVE =
  'crossSellDiscountActive'
export const METADATA_TAG = 'metadata'
export const PROM_CROSS_SELL_PREFIX = 'CD'

// Cookie fields
export const USER_HASH_ID_IN_COOKIE = 'usrHashId'
export const CUSTOMER_TYPE_IN_COOKIE = 'usrType'
export const COOKIE_USER_COUNTRY = 'userCountry'
export const COOKIE_CONTAINER = 'x-container'
export const USER_LOCATION_POPUP_INTERACTION = 'locationPopupInteractionOccured'
export const COOKIE_VISITOR_ID = 'visitor_id'
export const COOKIE_IRC_CLICK_ID = 'irclickid'
export const COOKIE_FEATURES = 'features'
export const COOKIE_SIGNUP_FORM_FINISHED = 'signUpForm'
export const COOKIE_HUBSPOT = 'hubspotutk'
export const COOKIE_BC_CART = 'bc_cartId'
export const getCookieRequestAQuoteFormId = (productSlug) =>
  `${productSlug}-request-a-quote-form`
export const getCookiePlusEditorFormId = (productSlug) =>
  `${productSlug}-plus-editor`

export const COOKIE_IMPERSONATE = 'impersonate'

export const COOKIE_DATALAYER = 'basicLayer'

export const IMPERSONATE_MAX_AGE = 30 * 60 // ONE HOUR

export const MEGAMENU_BREADCRUMBS_SESSION_KEY = 'megaMenuTrackRoutes'

export const AUTH_EVENTS_GTM_QUERY = 'trackAuthEvents'

export const EUROPEAN_COUNTRIES = [
  'AT',
  'BE',
  'HR',
  'BG',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'CH',
  'NO',
  'IS',
  'LI',
]

export const KEY_CODES = {
  ENTER: 13,
  ESCAPE: 27,
  ARROW_UP: 38,
  ARROW_DOWN: 40,
}
