import { FC } from 'react'
import NextHead from 'next/head'
import { DefaultSeo, NextSeo } from 'next-seo'
import config from '@config/seo.json'
import { NEXT_PUBLIC_DOMAIN_NAME } from '@utils/client-side-config'

/*
  Micro sites use Gatsby which have their own client side router/link
  and it will change the url using JS script on client side. So the href
  generated here won't reflect the correct window.location url for those
  micro sites. It will be controlled/offerred from microsites rather than
  from here to make the link correct
 */
const excludedMicrosites = [
  '/blog/',
  '/community/',
  '/sustainability/',
  '/privacy-policy/',
  '/noissue-recommends/',
]

const isPathExcluded = (path) => {
  for (const excludedPath of excludedMicrosites) {
    if (path.startsWith(excludedPath)) {
      return true
    }
  }
  return false
}

const Head: FC<{ asPath: string }> = ({ asPath }) => {
  const strippedPath = asPath?.split(/[?#]/)[0]
  const pathName = strippedPath === '/content/homepage' ? '/' : strippedPath
  return (
    <>
      {!isPathExcluded(asPath) && (
        <>
          <DefaultSeo {...config} />
          <NextSeo
            canonical={`https://${NEXT_PUBLIC_DOMAIN_NAME}${pathName}`}
          />
        </>
      )}
      <NextHead>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="manifest" href="/site.webmanifest" key="site-manifest" />
        <link rel="icon" href="/images/favicon.png" />
        {!isPathExcluded(asPath) && (
          <>
            <link
              rel="alternate"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              hreflang="x-default"
              href={`https://noissue.co${pathName}`}
            />
            <link
              rel="alternate"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              hreflang="en-ca"
              href={`https://noissue.ca${pathName}`}
            />
            <link
              rel="alternate"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              hreflang="en-nz"
              href={`https://noissue.co.nz${pathName}`}
            />
            <link
              rel="alternate"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              hreflang="en-gb"
              href={`https://noissue.co.uk${pathName}`}
            />
            <link
              rel="alternate"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              hreflang="en-au"
              href={`https://noissue.com.au${pathName}`}
            />
          </>
        )}
      </NextHead>
    </>
  )
}

export default Head
