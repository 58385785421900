// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || 'sandbox',
  release: process.env.NEXT_PUBLIC_SENTRY_RELEASE,
  dsn:
    SENTRY_DSN ||
    'https://9b992132da5e42f68ab77ff00111bd38@o338168.ingest.sentry.io/5747973',
  enableTracing: false,
  tracesSampleRate: 0,
  initialScope: {
    tags: {
      channel: process.env.NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_COUNTRY,
    },
  },
})
