import { IPdfGenerationPayload, TDesignSide } from './onlineEditor/editor.types'
import { TDesignJourneyWithArtwork, TLegacyDesignJourney } from './types'
import { v4 as uuidv4 } from 'uuid'

const legacyDesignJourneyToNew: Record<
  TLegacyDesignJourney,
  TDesignJourneyWithArtwork
> = {
  V1: 'upload',
  V2: 'editor',
  V3: 'briefing',
}
const newDesignJourneyToLegacy: Record<
  TDesignJourneyWithArtwork,
  TLegacyDesignJourney
> = {
  upload: 'V1',
  editor: 'V2',
  briefing: 'V3',
}

const LAST_SUCCESSFUL_STATUS = 226

const uploadCanvasSvg = async ({
  svgString,
  designId,
  designSide = 'front',
}: {
  svgString: string
  designId: string
  designSide: TDesignSide
}) => {
  const svgUploadLinkResponse = await fetch(
    '/api/design/online-editor/upload-design-svg',
    {
      method: 'POST',
      body: JSON.stringify({
        designId,
        designSide,
      }),
    }
  )

  const presignedPost = await svgUploadLinkResponse.json()
  const { url, fields } = presignedPost
  const svgFile = new File([svgString], 'designCanvasSvg.svg')

  const formData = new FormData()

  Object.entries({ ...fields, file: svgFile }).forEach(([key, value]) => {
    formData.append(key, value as any)
  })

  const uploadResult = await fetch(url, {
    method: 'POST',
    body: formData,
  })

  if (uploadResult.status > LAST_SUCCESSFUL_STATUS) {
    throw Error(uploadResult.statusText)
  }
}

const generatePDF = async (
  designDataPayload: IPdfGenerationPayload,
  parentOrderDesignId: string
) => {
  const designId = uuidv4()
  const uploadedDesignPaths: Record<TDesignSide, string> = {
    front: null,
    back: null,
  }

  for (let side of designDataPayload?.sidesToPrint) {
    if (designDataPayload.svgWithDesign[side]) {
      await uploadCanvasSvg({
        svgString: designDataPayload.svgWithDesign[side],
        designId,
        designSide: side as TDesignSide,
      })

      uploadedDesignPaths[side] = `${designId}/${side}.svg`
    } else {
      if (parentOrderDesignId) {
        uploadedDesignPaths[side] = `${parentOrderDesignId}/${side}.svg`
      }
    }
  }

  const generationResult = await fetch(
    '/api/design/online-editor/generate-pdf',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        productInfo: designDataPayload.productInfo,
        artboard: designDataPayload.artboard,
        reorderId: designDataPayload.reorderId,
        pdfLayoutConfig: designDataPayload.pdfLayoutConfig,
        sidesToPrint: designDataPayload.sidesToPrint,
        designFilesPaths: uploadedDesignPaths,
        designId,
      }),
    }
  )

  if (generationResult.status > LAST_SUCCESSFUL_STATUS) {
    throw new Error(generationResult.statusText)
  }

  const generatedPdfUrl = (await generationResult.json())?.pdfUrl

  return {
    pdfUrl: generatedPdfUrl,
    designId,
  }
}

export { legacyDesignJourneyToNew, generatePDF, newDesignJourneyToLegacy }
